import React, { lazy } from 'react'
import { ContractDocumentExpiryForm } from './ContractDocumentExpiry/ContractDocumentExpiryForm'


const AssetAssignedListing = lazy(() =>
  import('./AssetAssigned/AssetAssignedListing').then(module => ({
    default: module.AssetAssignedListing,
  }))
)

const AssetAssignedForm = lazy(() =>
  import('./AssetAssigned/AssetAssignedForm').then(module => ({
    default: module.AssetAssignedForm,
  }))
)





const ReportPreview = lazy(() =>
  import('./ReportPreview').then(module => ({
    default: module.ReportPreview,
  }))
)
const AppraisalRecordListing = lazy(() =>
  import('./AppraisalRecord/AppraisalRecordListing').then(module => ({
    default: module.AppraisalRecordListing,
  }))
)

const AppraisalRecordForm = lazy(() =>
  import('./AppraisalRecord/AppraisalRecordForm').then(module => ({
    default: module.AppraisalRecordForm,
  }))
)

const DiaryListing = lazy(() =>
  import('./Diary/DiaryListing').then(module => ({
    default: module.DiaryListing,
  }))
)

const DiaryForm = lazy(() =>
  import('./Diary/DiaryForm').then(module => ({
    default: module.DiaryForm,
  }))
)

const TrainingRecordListing = lazy(() =>
  import('./TrainingRecord/TrainingRecordListing').then(module => ({
    default: module.TrainingRecordListing,
  }))
)

const TrainingRecordForm = lazy(() =>
  import('./TrainingRecord/TrainingRecordForm').then(module => ({
    default: module.TrainingRecordForm,
  }))
)

const InsuranceSchemeListing = lazy(() =>
  import('./InsuranceScheme/InsuranceSchemeListing').then(module => ({
    default: module.InsuranceSchemeListing,
  }))
)
const InsuranceSchemeDetailListing = lazy(() =>
  import('./InsuranceScheme/InsuranceSchemeDetailListing').then(module => ({
    default: module.InsuranceSchemeDetailListing,
  }))
)

const InsuranceSchemeForm = lazy(() =>
  import('./InsuranceScheme/InsuranceSchemeForm').then(module => ({
    default: module.InsuranceSchemeForm,
  }))
)
const InsuranceSchemeEditForm = lazy(() =>
  import('./InsuranceScheme/InsuranceSchemeEditForm').then(module => ({
    default: module.InsuranceSchemeEditForm,
  }))
)

const PersonnelListing = lazy(() =>
  import('./PersonnelEFile/PersonnelListing').then(module => ({
    default: module.PersonnelListing,
  }))
)

const PersonnelEmployeeListing = lazy(() =>
  import('./EmploymentInfo/PersonnelEmployeeListing').then(module => ({
    default: module.PersonnelEmployeeListing,
  }))
)

const DataChangesApplicationListing = lazy(() =>
  import('./DataChangesApplication/DataChangesApplicationListing').then(
    module => ({
      default: module.DataChangesApplicationListing,
    })
  )
)

const DataChangesApplicationDetails = lazy(() =>
  import('./DataChangesApplication/DataChangesApplicationDetails').then(
    module => ({
      default: module.DataChangesApplicationDetails,
    })
  )
)

const PersonnelEmployeeListingForm = lazy(() =>
  import('./EmploymentInfo/PersonnelEmployeeForm').then(module => ({
    default: module.EmployeeListingForm,
  }))
)
const ProbationExtensionListing = lazy(() =>
  import(
    '../PersonnelModule/ProbationExtension/ProbationExtensionListing'
  ).then(module => ({
    default: module.ProbationExtensionListing,
  }))
)
const ContractDocumentExpiryListing = lazy(() =>
  import(
    '../PersonnelModule/ContractDocumentExpiry/ContractDocumentExpiryListing'
  ).then(module => ({
    default: module.ContractDocumentExpiryListing,
  }))
)
const StaffConfirmationListing = lazy(() =>
  import('../PersonnelModule/StaffConfirmation/StaffConfirmationListing').then(
    module => ({
      default: module.StaffConfirmationListing,
    })
  )
)
const StaffConfirmationForm = lazy(() =>
  import('../PersonnelModule/StaffConfirmation/StaffConfirmationForm').then(
    module => ({
      default: module.StaffConfirmationForm,
    })
  )
)
const StaffConfirmationEditForm = lazy(() =>
  import('../PersonnelModule/StaffConfirmation/StaffConfirmationEditForm').then(
    module => ({
      default: module.StaffConfirmationEditForm,
    })
  )
)
const StaffDemotionListing = lazy(() =>
  import('../PersonnelModule/StaffDemotion/StaffDemotionListing').then(
    module => ({
      default: module.StaffDemotionListing,
    })
  )
)

const StaffDemotionForm = lazy(() =>
  import('../PersonnelModule/StaffDemotion/StaffDemotionForm').then(module => ({
    default: module.StaffDemotionForm,
  }))
)
const StaffRetirementListing = lazy(() =>
  import('../PersonnelModule/StaffRetirement/StaffRetirementListing').then(
    module => ({
      default: module.StaffRetirementListing,
    })
  )
)
const StaffRetirementForm = lazy(() =>
  import('../PersonnelModule/StaffRetirement/StaffRetirementForm').then(
    module => ({
      default: module.StaffRetirementForm,
    })
  )
)
// const StaffRetirementEditForm = lazy(() =>
//   import('../PersonnelModule/StaffRetirement/StaffRetirementEditForm').then(
//     module => ({
//       default: module.StaffRetirementEditForm,
//     })
//   )
// )

const StaffInternalTransferListing = lazy(() =>
  import(
    '../PersonnelModule/StaffInternalTransfer/StaffInternalTransferListing'
  ).then(module => ({
    default: module.StaffInternalTransferListing,
  }))
)

const StaffInternalTransferForm = lazy(() =>
  import(
    '../PersonnelModule/StaffInternalTransfer/StaffInternalTransferForm'
  ).then(module => ({
    default: module.StaffInternalTransferForm,
  }))
)

const StaffExitCompanyListing = lazy(() =>
  import('../PersonnelModule/StaffExitCompany/ExitCompanyListing').then(
    module => ({
      default: module.StaffExitCompanyListing,
    })
  )
)

const StaffExitCompanyForm = lazy(() =>
  import('../PersonnelModule/StaffExitCompany/ExitCompanyForm').then(
    module => ({
      default: module.StaffExitCompanyForm,
    })
  )
)

const StaffPromotionListing = lazy(() =>
  import('../PersonnelModule/StaffPromotion/StaffPromotionListing').then(
    module => ({
      default: module.StaffPromotionListing,
    })
  )
)
const StaffPromotionForm = lazy(() =>
  import('../PersonnelModule/StaffPromotion/StaffPromoForm').then(module => ({
    default: module.StaffPromotionForm,
  }))
)

const StaffTerminationListing = lazy(() =>
  import('../PersonnelModule/StaffTermination/StaffTerminationList').then(
    module => ({
      default: module.StaffTerminationListing,
    })
  )
)

const StaffTerminationForm = lazy(() =>
  import('../PersonnelModule/StaffTermination/StaffTerminationForm').then(
    module => ({
      default: module.StaffTerminationForm,
    })
  )
)

const StaffRedesignationListing = lazy(() =>
  import(
    '../PersonnelModule/StaffRedesignation/StaffRedesignationListing'
  ).then(module => ({
    default: module.StaffRedesignationListing,
  }))
)
const StaffRedesignationForm = lazy(() =>
  import('../PersonnelModule/StaffRedesignation/StaffRedesignationForm').then(
    module => ({
      default: module.StaffRedesignationForm,
    })
  )
)

const InterCompanyTransferListing = lazy(() =>
  import(
    '../PersonnelModule/InterCompanyTransfer/InterCompanyTransferListing'
  ).then(module => ({
    default: module.InterCompanyTransferListing,
  }))
)
const InterCompanyTransferForm = lazy(() =>
  import(
    '../PersonnelModule/InterCompanyTransfer/InterCompanyTransferForm'
  ).then(module => ({
    default: module.InterCompanyTransferForm,
  }))
)
const InterCompanyTransferDetail = lazy(() =>
  import(
    '../PersonnelModule/InterCompanyTransfer/InterCompanyTransferDetail'
  ).then(module => ({
    default: module.InterCompanyTransferDetail,
  }))
)

const PersonnelRoutes = [
 
  {
    props: { exact: true, path: '/Employee/AssetAssigned' },
    component: <AssetAssignedListing />,
  },
  {
    props: { exact: true, path: '/Employee/AssetAssignedForm' },
    component: <AssetAssignedForm />,
  },


  {
    props: { exact: true, path: '/Employee/AppraisalRecordListing' },
    component: <AppraisalRecordListing />,
  },
  {
    props: { exact: true, path: '/Employee/AppraisalRecordForm/add' },
    component: <AppraisalRecordForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/AppraisalRecordForm/:appraisalRecordID/edit',
    },
    component: <AppraisalRecordForm mode="edit" />,
  },
  {
    props: { exact: true, path: '/Employee/DiaryListing' },
    component: <DiaryListing />,
  },
  {
    props: { exact: true, path: '/Employee/DiaryForm/add' },
    component: <DiaryForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/DiaryForm/:diaryID/edit',
    },
    component: <DiaryForm mode="edit" />,
  },
  {
    props: { exact: true, path: '/Employee/TrainingRecordListing' },
    component: <TrainingRecordListing />,
  },
  {
    props: { exact: true, path: '/Employee/TrainingRecordForm/add' },
    component: <TrainingRecordForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/TrainingRecordForm/:trainingID/edit',
    },
    component: <TrainingRecordForm mode="edit" />,
  },
  {
    props: { exact: true, path: '/Employee/InsuranceSchemeListing' },
    component: <InsuranceSchemeListing />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/InsuranceSchemeListing/InsuranceSchemeDetailListing',
    },
    component: <InsuranceSchemeDetailListing />,
  },
  {
    props: { exact: true, path: '/Employee/InsuranceSchemeForm/add' },
    component: <InsuranceSchemeForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/InsuranceSchemeForm/:employeeInsuranceID/edit',
    },
    component: <InsuranceSchemeForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/InsuranceSchemeEditForm/:employeeInsuranceID/edit',
    },
    component: <InsuranceSchemeEditForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/personnellisting',
    },
    component: <PersonnelListing />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeelisting',
    },
    component: <PersonnelEmployeeListing />,
  },
  {
    props: {
      exact: true,
      path: '/DataChangesApplication',
    },
    component: <DataChangesApplicationListing />,
  },
  {
    props: {
      exact: true,
      path: '/DataChangesApplication/DataChangesApplicationDetails',
    },
    component: <DataChangesApplicationDetails />,
  },
  {
    props: {
      exact: true,
      path: '/personnelemployeeform',
    },
    component: <PersonnelEmployeeListingForm />,
  },
  {
    props: {
      exact: true,
      path: '/probationextension/probationextensionlisting',
    },
    component: <ProbationExtensionListing />,
  },
  {
    props: {
      exact: true,
      path: '/contractdocumentexpiry/contractdocumentexpirylisting',
    },
    component: <ContractDocumentExpiryListing />,
  },
  {
    props: {
      exact: true,
      path: '/contractdocumentexpiry/contractdocumentexpirylisting/contractdocumentexpiryform',
    },
    component: <ContractDocumentExpiryForm />,
  },
  {
    props: {
      exact: true,
      path: '/staffconfirmation/staffconfirmationlisting',
    },
    component: <StaffConfirmationListing />,
  },
  {
    props: {
      exact: true,
      path: '/staffconfirmation/staffconfirmationlisting/staffconfirmationform',
    },
    component: <StaffConfirmationForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/staffconfirmation/staffconfirmationlisting/staffconfirmationEditform',
    },
    component: <StaffConfirmationEditForm />,
  },
  {
    props: {
      exact: true,
      path: '/staffdemotion/staffdemotionlisting',
    },
    component: <StaffDemotionListing />,
  },
  {
    props: {
      exact: true,
      path: '/staffdemotion/staffdemotionform/new',
    },
    component: <StaffDemotionForm />,
  },
  {
    props: {
      exact: true,
      path: '/staffdemotion/staffdemotionform/edit',
    },
    component: <StaffDemotionForm />,
  },
  {
    props: {
      exact: true,
      path: '/staffexitcompany/staffexitcompanylisting',
    },
    component: <StaffExitCompanyListing />,
  },
  {
    props: {
      exact: true,
      path: '/staffretirement/staffretirementlisting',
    },
    component: <StaffRetirementListing />,
  },
  {
    props: {
      exact: true,
      path: '/staffexitcompany/staffexitcompanyform/new',
    },
    component: <StaffExitCompanyForm />,
  },
  {
    props: {
      exact: true,
      path: '/staffretirement/staffretirementlisting/staffretirementform',
    },
    component: <StaffRetirementForm />,
  },
  {
    props: {
      exact: true,
      path: '/staffretirement/staffretirementlisting/staffretirementform/edit',
    },
    component: <StaffRetirementForm />,
  },
  {
    props: {
      exact: true,
      path: '/staffexitcompany/staffexitcompanyform/edit',
    },
    component: <StaffExitCompanyForm />,
  },
  {
    props: { exact: true, path: '/StaffPromotionListing' },
    component: <StaffPromotionListing />,
  },
  {
    props: { exact: true, path: '/StaffPromotionForm' },
    component: <StaffPromotionForm />,
  },
  {
    props: { exact: true, path: '/StaffRedesignationListing' },
    component: <StaffRedesignationListing />,
  },
  {
    props: { exact: true, path: '/StaffRedesignationForm' },
    component: <StaffRedesignationForm />,
  },
  {
    props: { exact: true, path: '/StaffTerminationListing' },
    component: <StaffTerminationListing />,
  },
  {
    props: { exact: true, path: '/StaffTerminationForm' },
    component: <StaffTerminationForm />,
  },

  {
    props: { exact: true, path: '/InterCompanyTransferListing' },
    component: <InterCompanyTransferListing />,
  },
  {
    props: { exact: true, path: '/InterCompanyTransferForm' },
    component: <InterCompanyTransferForm />,
  },
  {
    props: { exact: true, path: '/InterCompanyTransferDetail' },
    component: <InterCompanyTransferDetail />,
  },
  {
    props: {
      exact: true,
      path: '/staffinternaltransfer/staffinternaltransferlisting',
    },
    component: <StaffInternalTransferListing />,
  },
  {
    props: {
      exact: true,
      path: '/staffinternaltransfer/staffinternaltransferform/new',
    },
    component: <StaffInternalTransferForm />,
  },
  {
    props: {
      exact: true,
      path: '/staffinternaltransfer/staffinternaltransferform/edit',
    },
    component: <StaffInternalTransferForm />,
  },

  {
    props: {
      exact: true,
      path: '/reportpreview/:Category',
    },
    component: <ReportPreview />,
  },
]

export default PersonnelRoutes
